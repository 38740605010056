import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import { Link } from "react-router-dom";
import {API_GET, API_POST} from "../../services/api.service";
import _ from "underscore";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
// import Filters from "./Filter";
import Button from "components/CustomButtons/Button.jsx";


import Highcharts from 'highcharts';
import drilldown from 'highcharts/modules/drilldown.js';
import HighchartsReact from 'highcharts-react-official';
import CustomTabs from "../../components/CustomTabs/CustomTabs";
import ErrorBoundary from "../../hoc/ErrorBoundary";
import Followups from "../Schedule/Followups";
import Appointment from "../Schedule/Appointment";
import DispositionDetails from "./DispositionDetails";
import ReportsStats from "../ReportStats/reportsStats";
import InfoIcon from "@material-ui/icons/Info";
import Typography from "@material-ui/core/Typography";
import InfoBox from "../../components/InfoIcon/InfoIcon";


//import _ from "underscore";

const filterquery={ $and: [ { $or: [{ IsActive: { $exists: false } }, { IsActive: true }] }   ]};
let mysq={};


const chartOption={
  "title": {
    "text": "WorkLoad"
  },
  "tooltip": {
    "pointFormat": "<span style=\"color:{point.color};\">{series.name}</span>: <b>{point.y}</b>",
    "backgroundColor": "white"
  },
  "chart": {
    "type": "pie",
  },
  "plotOptions": {
    "series": {
      "cursor": "pointer",
      "dataLabels": {
        "color": "#428bca",
        "distance": -30,
        "enabled": true,
        "backgroundColor": "white",
        "format": "{point.percentage:.1f}%"
      },
      "showInLegend": true,
      "allowPointSelect": true
    }
  },
  "series": [
    {
      "name": "Workload",
      "id": "Workload",
      "colorByPoint": true,
      "data": null
    }
  ]
};

function Workload(props) {
  const { user } = props;
  const [data, setData] = useState([]);
  const [disposition, setDisposition] = useState([]);
  let dispositionColumnDone = 0;


  const loadWorkloadData = async () => {
    

    let res = await API_GET("action/AggregateReport", null, {
      admin: user && user.admin
    });

    let data=res.data;




    setData(data || []);

    

    //setChartOption(_chartOption);
  };

  const columns = [
    {
      name: "User",
      selector: "username",
      width:'220px',

    },

    {
      name: "Assigned Prospects",
      selector: "assigned",
      cell: (row) => (
          <>
          {
            row.username !='Total' ? (
                <Link to={'/admin/ListProspects?query='+btoa(JSON.stringify({ $and: [ { $or: [{ IsActive: { $exists: false } }, { IsActive: true }] },{assignTo:row.username}   ]}))}>
                  {row.assigned}</Link>
            ) : row.assigned
          }
          </>
      ),
    },

    {
      name: "Attempted",
      selector: "attempt",
      cell: (row) => (
          <>
            {
              row.username !='Total' ? (
                  <Link to={'/admin/ListProspects?query='+btoa(JSON.stringify({ $and: [ { $or: [{ IsActive: { $exists: false } }, { IsActive: true }] },{assignTo:row.username}   ],disposition: { $ne: null } }))}>
                    {row.attempt}</Link>
              ) : row.attempt
            }
          </>
      ),
    },
    {
      name: "Not Attempted",
      selector: "notAttempt",
      cell: (row) => (
          <>
            {
              row.username !='Total' ? (
                  <Link to={'/admin/ListProspects?query='+btoa(JSON.stringify({ $and: [ { $or: [{ IsActive: { $exists: false } }, { IsActive: true }] },{assignTo:row.username}   ],$or: [{ disposition: { $exists: false } }, { disposition: null }] }))}>
                    {row.notAttempt}</Link>
              ) : row.notAttempt
            }
          </>
      ),
    },

    {
      name: "Open",
      selector: "open",
      cell: (row) => (
          <>
            {
              row.username !='Total' ? (
                  <Link to={'/admin/ListProspects?query='+btoa(JSON.stringify({ $and: [ { $or: [{ IsActive: { $exists: false } }, { IsActive: true }] },{assignTo:row.username}   ], open: true  }))}>
                    {row.open}</Link>
              ) : row.open
            }
          </>
      ),
    },

    {
      name: "Close",
      selector: "close",
      cell: (row) => (
          <>
            {
              row.username !='Total' ? (
                  <Link to={'/admin/ListProspects?query='+btoa(JSON.stringify({ $and: [ { $or: [{ IsActive: { $exists: false } }, { IsActive: true }] },{assignTo:row.username}   ],open: false  }))}>
                    {row.close}</Link>
              ) : row.close
            }
          </>
      ),
    },
    {
      name: "Upcoming Callbacks",
      selector: "followups",
      cell: (row) => (
          <>
            {
              row.username !='Total' ? (
                  <Link to={'/admin/ListProspects?query='+btoa(JSON.stringify({ IsActive: true,assignTo: row.username,callback: { $gte: new Date() } }  ))}>
                    {row.followups}</Link>
              ) : row.followups
            }
          </>
      ),
    },
    {
      name: "Overdue Callbacks",
      selector: "overdue",
      cell: (row) => (
          <>
            {
              row.username !='Total' ? (
                  <Link to={'/admin/ListProspects?query='+btoa(JSON.stringify({ IsActive: true,assignTo: row.username,callback: { $lt: new Date() } }  ))}>
                    {row.overdue}</Link>
              ) : row.overdue
            }
          </>
      ),
    },


  ];
  useEffect(() => {
    if(user && user.admin)
    loadWorkloadData();
  }, [user]);

  const getColName =(selector)=>{
    for (let index = 0; index < columns.length; index++) {
      const element = columns[index];
      if(element.selector == selector){
        return element.name;
      }
      
    }
  }

  const bindChart = () =>{
    
    let _chartOption = {...chartOption};
    _chartOption.series[0]['data'] = null
    let obj = [];
    for (const key in data.TotalInfo) {
      if (Object.hasOwnProperty.call(data.TotalInfo, key)) {
        const element = data.TotalInfo[key];
        if(!isNaN(element)){
          obj.push({
            "y": element,          
            "name": getColName(key),
            "id": key,
          })
        }
      }
    }
    _chartOption.series[0]['data'] = obj

    return _chartOption;
  }

  
  return (
    <>
      <Card>



        <GridItem xs={12} sm={12} md={12}>
          <CustomTabs
              title=""
              headerColor="info"
              tabs={[
                {
                  tabName: "Basic Report",
                  tabContent: (
                      <>
                        <CardHeader color="transparent">
                          <GridContainer>

                            <GridItem xs={12} sm={12} md={12}>
                            <GridItem  style={{float: "right", marginTop: "10px"}}>
                              <InfoBox name={'Report.BasicReport'} />
                            </GridItem>
                              <GridItem>
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={bindChart()}
                                    allowChartUpdate={true}
                                />
                              </GridItem>
                            </GridItem>
                          </GridContainer>
                        </CardHeader>
                      <CardBody>
                        <DataTable
                            columns={columns}
                            data={data.data || []}
                            highlightOnHover
                            striped
                        />
                      </CardBody>
                  </>
                  ),
                },
                {
                  tabName: "Disposition Report",
                  tabContent: (
                      <CardBody>
                        <DispositionDetails
                          user={user}
                          disposition={disposition}
                        />
                      </CardBody>
                  ),
                },
                {
                  tabName: "Monthly Report",
                  tabContent: (
                      <CardBody>
                        <ReportsStats {...props}
                        />
                      </CardBody>
                  ),
                },
              ]}
          />
        </GridItem>



      </Card>
    </>
  );
}

export default Workload;


