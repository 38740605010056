import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import dayjs from "dayjs";
//import axios from 'axios';
import { Info } from "@material-ui/icons";
import Button from "components/CustomButtons/Button.jsx";
import { API_GET, API_POST } from "../../services/api.service";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import Snackbar from "../../components/Snackbar/Snackbar";
import {Box} from "@material-ui/core";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import InfoBox from "../../components/InfoIcon/InfoIcon";




const columns = [
    {
        name: "Url",
        width: "150px",
        selector: "requestURL",
        cell: (row) => (
            <span title={row.requestURL}>{ sliceString(row.requestURL) }...</span>
        ),
    },
    {
        name: "Source",
        selector: "method",
        width: "100px",
    },

    {
        name: "Request Body",
        width: "300px",
        cell: (row) => (
            <pre title={JSON.stringify(row.requestPayload)}>{ sliceString(JSON.stringify(row.requestPayload),30) }...</pre>
        ),
    },

    {
        name: "Response",
        width: "300px",
        cell: (row) => (
            <pre title={JSON.stringify(row.responseData)}>{ sliceString(JSON.stringify(row.responseData),30) }...</pre>
        ),
    },
    {
        name: "Created On",
        cell: (row) => (
            <span>{dayjs(row.requestTime).format("DD/MM/YYYY hh:mm")}</span>
        ),
    },

];


function sliceString(str,c=20){
    if(!str){
        return str;
    }else{
        return  str.slice(0,c);
    }
}




function ApiLog(props) {
    const search = props.location &&  props.location.search;
    let typeParam = new URLSearchParams(search).get('type');
    let typeIdp = new URLSearchParams(search).get('id');

    const [type, setType] = useState(typeParam);
    const [typeId, setTypeId] = useState(typeIdp);
    const [open, setOpen] = useState(false);
    const { classes, user } = props;
    const [data, setData] = useState({});
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [skip, setSkip] = useState(0);
    const countPerPage = 10;
    const [SelectedStatus, setSelectedStatus] = useState([]);
    const [prospectId,setProspectId]=useState([]);
    const [apiProspect,setApiProspect]=useState(false);
    const urlParams = new URLSearchParams(props.location && props.location.search);
    const fid = urlParams.get("fid");
    const [notify, setNotify] = useState({
        open: false,
        message: null,
        color: "success",
    });

    const pageLoadquery = async () => {
        debugger;
        let con;

        try{

            const urlParam = new URLSearchParams(window.location.search);
            let search = urlParam.get("Search");

            if (!search) {
                if (fid) {
                    con = { ...con, fid };
                } else {
                    con = { ...con };
                }
            } else {
                if (search) {
                    search = JSON.parse(atob(search));
                }

                con = { ...search, admin: user && user.admin };

                //   if (user && ["field_exc", "agent"].indexOf(user.role) > -1) {
                //     con = { ...con, caller: user && user.username };
                //   }
            }
        }catch(e){

        }

        return con;
    };

    const handleExportExcel = async (e) => {
        let downloadFile = await API_POST(`action/ExportExcel`, {
            querydata: {admin: user && user.admin,fid:fid ? fid :null },
            body: {  admin: user.admin, user: user.username,page:"prospecthistory" },
        });
        if (downloadFile.status == 200) {
            setNotify({
                open: true,
                message: "File is in process,Please check in file section.",
                color: "success",
            });
        }
    };

    const getUserList = async () => {
        if (user && user.admin) {
            //let con = await pageLoadquery();
            let con = { admin: user && user.admin };

            if (fid) {
                con = { ...con, fid };
            } else {
                con = { ...con, InSystem: "API" };
            }

            if (SelectedStatus && SelectedStatus.updateStatus) {
                con = { ...con, updateStatus:  SelectedStatus.updateStatus  };
            }

            let res = await API_GET(
                `action/getProspectHistory?page=${page}&per_page=${countPerPage}&delay=1`,
                null,
                {
                    query: con,
                }
            );
            // debugger;
            setData(res.data);

            let d=[];
            for (let i=0;i<res.data.data.length;i++){
                d.push(res.data.data[i]._id);
            }
            setProspectId(d);

        }
    };

    const filterData = async (data) => {
        //handleUrl(data);
        setSelectedStatus(data);

    };

//   const handleUrl = async (data) => {
//     let query;
//     query=data;
//     let search = btoa(JSON.stringify(query));
//     const { history } = props;

//     history.push({
//       pathname: `/admin/ProspectHistory?fid=${fid}`,
//       search: `Search=${search}`,
//     });
//   };



    const loadApiProspects =async () =>{
        let con = { 'admin': user && user.admin };
       // con = {  };

        if (fid) {
            con = { ...con, fid };
        } else {
            con = { ...con};
        }
        let body = {
            limit: 10,
            skip: skip,
            sort:  { _id: -1 },
          };

          let root = 'APILogs';
        if(type=='integration'){

            root='apiResponses';
        }  
        if(typeId){
            con = {...con,Iid:typeId};
            delete con.admin;
        }
        let res = await API_POST(
            `action/getdata`,
            {
                root:root,
                con: con,
                body
            }
        );
        // debugger;
        res.data.data = res.data.data.map((v)=>{
            
            v.requestURL = v.requestURL ? v.requestURL :v.url;
            v.method = v.method ? v.method : v.integrationName;
            v.responseData = v.responseData ? v.responseData :v.lead;
            v.requestTime = v.requestTime? v.requestTime :v.createdOn;
            return v;
        })
        setData(res.data);
        console.log(con);

        setTotal(res.data.rowCount)
        let d=[];
        for (let i=0;i<res.data.data.length;i++){
            d.push(res.data.data[i]._id);
        }
        setProspectId(d);


    }

    const apiLog = (e) => {
        setApiProspect(true);
        loadApiProspects();
    };
    useEffect(() => {

        setSkip((page - 1) * 10);
        apiLog();
    }, [page,SelectedStatus]);

    return (
        <div className="App">

            <Snackbar
                place="tr"
                color={notify.color}
                message={notify.message}
                open={notify.open}
                closeNotification={() => setNotify({ open: false })}
                close
            />

            <Card>
                <GridContainer>
                    {/*

          {
            apiProspect ?  <Tab label={"Api Logs"} onChange={apiLog} style={{background:'#265077'}}></Tab>  :   <Tab label={"Api Logs"} onChange={apiLog}></Tab>
          }

*/}


                    <GridItem xs={12} sm={12} md={12}>
                        <span style={{ float: "right", fontSize: "25px" }}>
                        <GridItem  style={{float: "left", marginTop: "10px"}}>
                            <InfoBox name={'Schedule.ApiLog'} />
                        </GridItem>
              {["manager", "owner"].indexOf(user && user.role) > -1 && (

                  <Button
                      size="sm"
                      color="transparent"

                      justIcon
                      title="Export To Excel ( Inserted Prospect will not be downloaded )"
                      onClick={() => {
                          handleExportExcel();
                      }}
                  >
                      <GetAppOutlinedIcon  style={{fontSize:"25px"}} />
                  </Button>

              )}
                </span>
                        <DataTable
                            title="API Logs"
                            columns={columns}
                            data={data.data}
                            highlightOnHover
                            pagination
                            paginationServer
                            paginationTotalRows={total}
                            paginationPerPage={countPerPage}
                            paginationComponentOptions={{
                                noRowsPerPage: true,
                            }}
                            onChangePage={(page) => setPage(page)}
                        />
                    </GridItem>
                </GridContainer>
            </Card>
        </div>
    );
}

export default ApiLog;
