import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import Forms from "./Forms.jsx";
import Disposition from "./Disposition.jsx";
import Tags from "./Tags.jsx";
import Card from "components/Card/Card.jsx";

import Code from "@material-ui/icons/Code";

class Main extends React.Component {
  constructor(props) {
    super(props);
    
    //console.log(user)
    // this.state = {
    //   user: null
    // }
  }

  async componentDidMount() {
    // let getSessionRequest;
    // getSessionRequest = await API_GET(`get-session`);
    // //Page Authorization
    // if (getSessionRequest && getSessionRequest.success) {
    //   //this.setState({ user: getSessionRequest.userInfo });

    // }
  }

  render() {
    const { user } = this.props;
    return (
      <Card>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <CustomTabs
              title=""
              headerColor="primary"
              tabs={[
                {
                  tabName: "Disposition",
                  // tabIcon: <Code />,
                  tabContent: <Disposition user={user} />,
                },
                {
                  tabName: "Tags",
                  
                  tabContent: <Tags user={user} />,
                },
              ]}
            />
          </GridItem>
        </GridContainer>
      </Card>
    );
  }
}

Main.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(dashboardStyle)(Main);
