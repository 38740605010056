import React, { useEffect, useState, useRef } from "react";
// @material-ui/core components
// core components
import DataTable from "react-data-table-component";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "../../components/DialogTitle/DialogTitle";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { API_POST } from "../../services/api.service";
import Chip from "@material-ui/core/Chip";
import CommonMapping from "./../CommonMapping/CommonMapping";
import { platform } from "os";

function Email(props) {
  const { classes, user, open, defaultData } = props; // Removed unused props: classes, IsEdit
  const [Campaigns, setCampaigns] = useState([]);
  const [InputType, setInputType] = useState("");
  const [MappingVal, setMappingVal] = useState([]);
  const [MappingWithMulObject, setMappingWithMulObject] = useState([]);
  const [PreviewData, setPreviewData] = useState([]);
  const [IsShowFieldMapping, setIsShowFieldMapping] = useState(false);
  const [IsPreviewData, setIsPreviewData] = useState(false);
  const [columns, setcolumns] = useState([]);
  const [mapping, setMapping] = useState([]);
  const [IsSaved, setIsSaved] = useState(false);
  const [email_idInput, setEmail_idInput] = useState(user.admin);
  const [selectedPlatform, setSelectedPlatform] = useState("");

  const childRef = useRef();

  const handleClose = () => {
    props.handleClose();
  };

  const [notify, setNotify] = useState({
    open: false,
    message: null,
    color: "success",
  });

  useEffect(() => {
    if (props.user) {
      loadCampaign();
    }
  }, [props.user]);

  useEffect(() => {
    if (open && defaultData && defaultData.hasOwnProperty("cid")) {
      setInputType({ id: defaultData.cid, name: defaultData.campaign });
      setMappingWithMulObject(defaultData.Mapping);

      let obj = defaultData.Mapping.reduce(function (result, current) {
        return Object.assign(result, current);
      }, {});

      setPreviewData([obj]);
      setMappingVal(obj);
      setIsShowFieldMapping(true);
    } else {
      setInputType("");
      setMappingWithMulObject([]);
      setPreviewData([]);
      setMappingVal({});
      setIsShowFieldMapping(false);
    }
  }, [defaultData, open]);

  const handlePlatformChange = (event) => {
    setSelectedPlatform(event.target.value);
  };


  console.log("platformmmmmmmmmmmmmmmmm", selectedPlatform)

  const loadCampaign = async () => {
    try {
      const registerRequest = await API_POST("action/getdata", {
        root: "campaigns",
        con: { admin: user && user.admin, IsActive: true },
      });

      if (registerRequest && registerRequest.status === 200) {
        if (registerRequest.data.length > 0) {
          setCampaigns(registerRequest.data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  console.log("email_idInputttttttttttttt", email_idInput)
  const handleSave = async () => {
    // childRef.current.handleAdd();
    handleSaveIntegration()
  };

  const handleSaveIntegration = async () => {
    let payload = {
      name: "Email",
      admin: user && user.admin,
      campaign: InputType.name,
      cid: InputType.id,
      Message: "",
      Mapping: mapping,
      from: email_idInput,
      dateFormat: "YYYYMMDD",
      IsActive: true,
      platform: selectedPlatform
    };

    try {
      const registerRequest = await API_POST("action/upsert", {
        root: "Integrations",
        body: payload,
        querydata: { admin: user.admin, name: "Email", cid: InputType._id },
      });

      if (registerRequest && registerRequest.status === 200) {
        setNotify({
          open: true,
          message: "Data Save Successfully",
          color: "success",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (IsSaved) {
      props.CallloadIntegrationList();
      handleSaveIntegration();
      setIsSaved(false);
    }
  }, [IsSaved]);

  // let col = [];
  // const handlePreview = () => {
  //   if (PreviewData.length === 0 || PreviewData[0].hasOwnProperty("Error_Message")) {
  //     setNotify({
  //       open: true,
  //       message: "Please first click on test btn to show mapping.",
  //       color: "danger",
  //     });
  //   } else {
  //     let obj = {};
  //     for (var key in PreviewData[0]) {
  //       if (PreviewData[0].hasOwnProperty(key)) {
  //         obj = { name: key, selector: key };
  //         col.push(obj);
  //       }
  //     }
  //     setcolumns(col);
  //     setIsPreviewData(true);
  //   }
  // };

  // const bindMapping = (mapping) => {
  //   setMapping(mapping);
  //   setIsSaved(true);
  // };

  return (
    <GridContainer>
      <Snackbar
        place="tr"
        color={notify.color}
        message={notify.message}
        open={notify.open}
        closeNotification={() => setNotify({ open: false })}
        close
      />
      <Dialog open={open} fullWidth={true} maxWidth="md">
        <DialogTitle id="customized-dialog-title">Email Setup</DialogTitle>

        <DialogContent>
          <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
              <CustomInput
                id="Name"
                labelText={"Name"}
                labelProps={{ shrink: true }}
                formControlProps={{
                  fullWidth: true,
                  multiline: false,
                }}
                inputProps={{
                  required: true,
                  value: "Email",
                  name: "Email",
                  disabled: true,
                }}
              />

              <Autocomplete
                onChange={(event, value) => setInputType(value)}
                id="combo-box-demo"
                size="small"
                options={Campaigns}
                labelText="FieldType"
                name="autoComplete"
                value={InputType || null}
                getOptionLabel={(Campaigns) => Campaigns.name || ""}
                defaultValue="Select"
                renderTags={(value) =>
                  value.map(() => (
                    <Chip variant="outlined" label="Enter Field" />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Campaign "
                  />
                )}
              />

              <CustomInput
                id="EmailId"
                labelText={"From *"}
                labelProps={{ shrink: true }}
                formControlProps={{
                  fullWidth: true,
                  multiline: false,
                }}
                inputProps={{
                  required: true,
                  value: email_idInput,
                  name: "EmailId",
                  onChange: (e) => {
                    setEmail_idInput(e.target.value);
                  },
                  //onBlur: handleBlur,
                }}
              />
              
              <TextField
                id="platform"
                select
                label="Select Platform"
                value={selectedPlatform}
                onChange={handlePlatformChange}
                fullWidth
              >
                <MenuItem value="Justdial">Justdial</MenuItem>
                <MenuItem value="IndiaMart">IndiaMart</MenuItem>
              </TextField>

            </GridItem>

            {/* <GridItem xs={12} sm={6} md={6}>
              {IsShowFieldMapping && (
                <CommonMapping
                  ref={childRef}
                  MappingInSingleObj={MappingVal}
                  MappingWithMulObject={MappingWithMulObject}
                  user={props.user}
                  bindMapping={bindMapping}
                />
              )}
            </GridItem> */}
          </GridContainer>
          {/* <GridContainer>
            {IsPreviewData && PreviewData && (
              <DataTable
                columns={columns}
                data={PreviewData || []}
                highlightOnHover
              />
            )}
          </GridContainer> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          {InputType && (
            <Button onClick={handleSave} color="primary">
              Save
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </GridContainer>
  );
}

export default Email;

class GenerateField extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      value,
      onChange,
      id,
      options,
      label,
      keyValuePair,
      type = "select",
      disabled = false,
    } = this.props;

    return (
      <GridItem xs={12} sm={12} md={12}>
        <CustomInput
          labelText={label}
          id
          labelProps={{ shrink: true }}
          formControlProps={{
            fullWidth: true,
            multiline: true,
          }}
          customOptions={options}
          inputProps={{
            required: false,
            value,
            defaultValue: value,
            checked: value,
            name: id,
            id: id,
            type: type,
            onChange,
            disabled,
            options: keyValuePair,
          }}
        />
      </GridItem>
    );
  }
}

